@if $toc {
  /* - Heading__________________Heading (h1 -> h6) */
} @else {
  /* Simple default styles for headings 1 through 6 [element]
   ========================================================================== */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div[role='heading'] {
    @include font($type: medium, $family: $serif);
    margin: 0;
    letter-spacing: normal;
    color: theme(heading-txt);
  }

  h1,
  h2,
  div[role='heading'][aria-level='2'] {
    margin-bottom: rem(16); // 16px
  }

  h3,
  h4,
  h5,
  h6 {
    margin-bottom: rem(8); // 8px
  }

  h1 {
    font-size: rem(34); // 34px
    line-height: lh(45, 34); // 45px
    font-weight: 600;

    // @include mq($from: S, $until: M) {
    @include mq($from: S) {
      font-size: rem(52); // 52px
      line-height: lh(65, 52); // 65px
    }

    @include mq($from: M) {
      // font-size: rem(70); // 70px
      // line-height: lh(80, 70); // 80px
    }
  }

  h2,
  div[role='heading'][aria-level='2'] {
    font-size: rem(24); // 24px
    line-height: lh(36, 24); // 36px

    @include mq($from: S) {
      font-size: rem(40); // 40px
      line-height: lh(46, 40); // 46px
    }
  }

  h3 {
    font-size: rem(20); // 20px
    line-height: lh(30, 20); // 30px

    @include mq($from: S) {
      font-size: rem(30); // 30px
      line-height: lh(40, 30); // 40px
      letter-spacing: 1px;
    }
  }

  h4 {
    font-size: rem(18); // 18px
    line-height: lh(26, 18); // 26px

    @include mq($from: S) {
      font-size: rem(24); // 24px
      line-height: lh(33, 24); // 33px
      letter-spacing: 0.8px;
    }
  }

  h5 {
    @include font($type: regular, $family: $sans-serif);
    font-size: rem(16); // 16px
    line-height: lh(24, 16); // 24px

    @include mq($from: S) {
      font-size: rem(20); // 20px
      line-height: lh(28, 20); // 28px
      letter-spacing: 0.4px;
    }
  }

  h6 {
    font-size: rem(16); // 16px
    line-height: lh(24, 16); // 24px

    @include mq($from: S) {
      font-size: rem(18); // 18px
      line-height: lh(26, 18); // 26px
      letter-spacing: 0.8px;
    }
  }
}
